import "./style.scss";

const loading = document.querySelector( '.loading' );
 window.addEventListener( 'load', () => {
  setTimeout(() => {
    loading.classList.add( 'hide' );
  }, 2500);
}, false );


// 着火点となる要素
const trigger = document.querySelectorAll('.trigger');

const options = {
	root: null,
	rootMargin : "-50% 0px",
  threshold: 0
};

// 実行するよ
const observer = new IntersectionObserver(showElements);

// 複数あるから forEach 使う
trigger.forEach(trigger => {
  observer.observe(trigger);
});

// 要素が表示されたら実行する動作
function showElements(entries){
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('active');
    }
  });
};

const mySwiper = new Swiper('.swiper', {
  // Optional parameters
  loop : true,
  effect : 'fade',
  fadeEffect : {
    crossFade : true
  },
  speed: 2000,
  autoplay: {
    delay: 7000,
    disableOnInteraction: false
  }
});

document.addEventListener('DOMContentLoaded', () => {
  
  mySwiper.autoplay.start();

  //checkbox toggle処理
  let checkbox = document.getElementById('nav-input');
  let label = document.getElementById('menubtn');
  let navBlock = document.querySelector('.header-navi');

  label.addEventListener('click', () => {
    if(checkbox.checked){
      navBlock.classList.replace('open','close')
    }else{
      navBlock.classList.replace('close','open')
    }
  });

  //SPナビ制御
  const navId = document.getElementById('header-navi');
  const hashtag = navId.querySelectorAll('a[href^="#"]');

  hashtag.forEach(hashtag => {
    hashtag.addEventListener('click', () => {
      checkbox.checked = false;
      navBlock.classList.replace('open','close')
    });
  });

  //headerの色反転用
  const scFunc = function() {
    const spot = document.getElementsByClassName('business');
    const header = document.getElementById('header')
    const position = Math.floor(window.innerHeight * .75);

    for(let i = 0; i < spot.length; i++){
      let offsetTop = Math.floor(spot[i].getBoundingClientRect().top);
      if(offsetTop < position){
        header.classList.replace('filter-on','filter-off');
      } 
      if(offsetTop > position) {
        header.classList.replace('filter-off','filter-on');
      }
    }
  }

  window.addEventListener('scroll', scFunc , false);

});

